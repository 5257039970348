import React from 'react';
import Routes from 'routes';
import './styles.scss';

function App() {
  return (
   <Routes/>
  );
}

export default App;
